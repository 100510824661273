import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';
import axiosClient from '../../axios-client';
import Input from '../../components/Input';
import { Button, Header } from '../../components';
import Alert from '../../components/Alert';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading';
import { BiSave } from 'react-icons/bi';
import SelectMulti from '../../components/SelectMulti';
import { GiCancel } from 'react-icons/gi';
import PrintRecipiet from '../factures/PrintRecipiet';
import { PiStudentBold } from 'react-icons/pi';

const AddInscription = () => {

  const [items, setItems] = useState([]);
  const [inputValue, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const { token, setToken } = useStateContext();
  const [moisScolaires, setMoisScolaires] = useState([]);
  const [type, setType] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [inputValueType, setValueType] = useState('');
  const [selected, setSelected] = useState([]);
  const [showPrint, setShowPrint] = useState(false);
  const [selectedFacture, setSelectedFacture] = useState(null);
  const [selectedPaiement, setSelectedPaiement] = useState(null);
  const [inscription, setInscription] = useState({
    id: '',
    nom: '',
    montant: '',
    reduction: 0,
    telParent: '',
    dispense: 0,
    paiement: 0,
    rim: '',
    nni: '',
    genre: '',
    dateNaissance: '',
    frais: 0,
    mois: selected,
    commentaire: '',
    classe: 0,
    total: 0,
    typePaiement: '',
    whatsapp: '',
    autreTel: '',
    telegram: '',
    nomAr:'',
  })
  const navigate = useNavigate();
  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };

  // handle selection


  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
    axiosClient.get(`/classe-get/${value.id}`)
      .then((data) => {
        setInscription({ ...inscription, montant: data.data.classe.prix, classe: data.data.classe.id });
      });


  }

  const handleInputChangeType = value => {
    setValueType(value);
  };

  // handle selection


  // handle selection
  const handleChangeType = value => {
    setSelectedType(value);
    setInscription({ ...inscription, typePaiement: value.libelle });

  }

  useEffect(() => {
    getClasses();
    getMois();
    getTypes();
  }, []);
  useEffect(() => {
    var tot = parseFloat(selected.length * inscription.montant) + parseFloat(inscription.frais) - parseFloat(inscription.dispense);
    setInscription({ ...inscription, mois: selected, total: tot });
  }, [selected]);
  useEffect(() => {
    let tot = parseFloat(selected.length * inscription.montant) + parseFloat(inscription.frais) - parseFloat(inscription.dispense);
    setInscription({ ...inscription, total: tot });

  }, [inscription.montant, inscription.frais, inscription.dispense, inscription.dispense]);
  const getClasses = () => {
    axiosClient.get('/classes')
      .then((data) => {
        setItems(data.data.classes);
      })
  }

  const getTypes = () => {
    axiosClient.get('/typePaiements')
      .then((data) => {
        setType(data.data.types);
      })
  }
  const getMois = () => {
    axiosClient.get('/mois-scolaires')
      .then((data) => {
        setMoisScolaires(data.data.mois);
      })
  }



  const onSubmit = () => {
    setErrors(null);
    setLoading(true);
    axiosClient.post('/inscription/save', inscription)
      .then((response) => {
        setLoading(true);
        if (response.data.message === "paiement") {
          setSelectedPaiement(response.data.paiement);
          setSelectedFacture(response.data.facture);
          setLoading(false);
          setShowPrint(true);
        }
        else {
          setLoading(false)
          close();
        }
      }).catch(err => {
        const response = err.response;
        if (response.status === 401) {
          setToken(null);
        }
        else {
          if (response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);

            toast.error('Merci de verifier les informations a saisies!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            if (response.status === 403) {
              navigate('/403');
            } else {
              if (response.status === 404) {
                navigate('/404');
              }
              else {
                navigate('/500');
              }
            }
          }

        }
      })
  }
  const close = () => {
    setShowPrint(false);
    setSelectedPaiement(null);
    navigate('/inscriptions');
  }

  return (
    <div>
      <Header icon={<PiStudentBold />} category='Page' title='Nouvelle Inscription' />
      {errors &&
        Object.keys(errors).map(key => (
          <Alert key={key} message={errors[key][0]} />
        ))
      }
      <form  >
        <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap ">
          <Input label="Nom" type="text" value={inscription.nom} onChange={ev => setInscription({ ...inscription, nom: ev.target.value })} required="required" />
          <Input label="Nom Ar" type="text" value={inscription.nomAr} onChange={ev => setInscription({ ...inscription, nomAr: ev.target.value })} required="required" />
          <Input label="Téléphone" type="text" value={inscription.telParent} onChange={ev => setInscription({ ...inscription, telParent: ev.target.value })} required="required" />
          <Input label="Whatsapp" type="text" value={inscription.whatsapp} onChange={ev => setInscription({ ...inscription, whatsapp: ev.target.value })} />
          
        </div>
        <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap mt-4">
          <Input label="Autre Tél" type="text" value={inscription.autreTel} onChange={ev => setInscription({ ...inscription, autreTel: ev.target.value })} />
          <Input label="Date de naissance" type="date" value={inscription.dateNaissance} onChange={ev => setInscription({ ...inscription, dateNaissance: ev.target.value })} />
          <Input label="Genre" type="text" value={inscription.genre} onChange={ev => setInscription({ ...inscription, genre: ev.target.value })} />
          <Input label="RIM" type="text" value={inscription.rim} onChange={ev => setInscription({ ...inscription, rim: ev.target.value })} />
          <Input label="NNI" type="text" value={inscription.nni} onChange={ev => setInscription({ ...inscription, nni: ev.target.value })} />
        </div>
        <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap mt-2">
          <div className="flex-col w-full">
            <label className="block text-sm font-medium leading-6 text-gray-900">Classe <span className='text-red-500'> *</span></label>
            <Select className='mt-2 outline-1 ' placeholder="Classe" cacheOptions defaultOptions value={selectedValue} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={items} onInputChange={handleInputChange} onChange={handleChange} />
          </div>
          <Input label="Mensualité" type="number" value={inscription.montant} onChange={ev => setInscription({ ...inscription, montant: ev.target.value })} required="required" />
          <Input label="Reduction %" type="number" value={inscription.reduction} onChange={ev => setInscription({ ...inscription, reduction: ev.target.value })} required="required" />
        </div>
        <div className='flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap w-full   mt-6'>
          <SelectMulti label="Mois" selected={selected} setSelected={setSelected} options={moisScolaires} required="required" />

        </div>
        <div className="flex flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap gap-1 mt-2">
          <Input label="Frais" type="number" value={inscription.frais} onChange={ev => setInscription({ ...inscription, frais: ev.target.value })} required="required" />
          <Input label="Dispense" type="number" value={inscription.dispense} onChange={ev => setInscription({ ...inscription, dispense: ev.target.value })} required="required" />
          <Input readOnly={true} label="Net à payer" type="number" value={inscription.total} onChange={ev => setInscription({ ...inscription, dispense: ev.target.total })} required="required" />
          <Input label="Payée" type="number" value={inscription.paiement} onChange={ev => setInscription({ ...inscription, paiement: ev.target.value })} required="required" />
          <div className="flex-col w-full">
            <label className="block text-sm font-medium leading-6 text-gray-900">M.paiement <span className='text-red-500'> *</span></label>
            <Select className='mt-2 outline-1 ' placeholder="M.Paiement" cacheOptions defaultOptions value={selectedType} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={type} onInputChange={handleInputChangeType} onChange={handleChangeType} />
          </div>
        </div>
        <div className='mt-4'>
          <Input label="Commentaire" type="text" value={inscription.commentaire} onChange={ev => setInscription({ ...inscription, commentaire: ev.target.value })} />
        </div>
        <div className="flex justify-end items-end mt-4">
          {loading ? <Loading /> :
            <>
              <div className='mr-2'>
                <Button color="bg-green-500" onClick={() => onSubmit()} icon={<BiSave />} size="20" text="Enregistrer" textColor="text-white" />
              </div>
              <Button color="bg-red-500" onClick={() => close()} icon={<GiCancel />} size="20" text="Annuler" textColor="text-white" />
            </>
          }
        </div>
      </form>
      {
        showPrint ? <PrintRecipiet paiement={selectedPaiement} onClose={close} facture={selectedFacture} /> : <></>
      }
    </div>
  )
}

export default AddInscription