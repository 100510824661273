import axios from "axios";

const axiosClient=axios.create({
    baseURL:"https://rim-log.com/AppsDesc/Ikhlass/public/api",
})

// les interceptors (request) sexecute avant qu'on envoi le rquest
axiosClient.interceptors.request.use((config)=>{
    const token=localStorage.getItem('ACCESS_TOKEN')
    config.headers.Authorization=`Bearer ${token}`
    return config;
})

axiosClient.interceptors.response.use((response)=>{
    return response;
},(error)=>{
     
     const {response}=error;
     if(response.status===401){ //unauthorize
        
        localStorage.removeItem('ACCESS_TOKEN');
     }
     throw error;
})
export default axiosClient;